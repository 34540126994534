import React from "react";
import styles from "./Products.module.css";
import Tabs from "./Tabs/Tabs";

const Products = ({
  tabData,
  title,
  desc,
  activeTab,
  setActiveTab,
  setOpenIndex,
}) => {
  return (
    <div className={styles.content}>
      <div className={styles.heading}>
        <h1>{title}</h1>
        <p>{desc}</p>
      </div>

      <div>
        <Tabs
          setOpenIndex={setOpenIndex}
          tabs={tabData}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
    </div>
  );
};

export default Products;

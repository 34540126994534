import React, { useEffect, useState } from "react";
import styles from "./AccordianMobile.module.css";

const AccordianMobile = ({ accordionData, activeTabIndex }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  useEffect(() => {
    // Reset activeItemIndex when activeTabIndex changes
    setActiveItemIndex(0);
  }, [activeTabIndex]);

  const currentTabItems = accordionData || [];
  const activeItem = currentTabItems[activeItemIndex];

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        {/* Scrollable Tabs Container */}
        <div className={styles.tabsContainer}>
          <div className={styles.tabsList}>
            {currentTabItems?.map((item, index) => (
              <button
                key={item.title}
                onClick={() => setActiveItemIndex(index)}
                className={`${styles.tab} ${
                  activeItemIndex === index ? styles.activeTab : ""
                }`}
              >
                {item.title}
                {activeItemIndex === index && (
                  <div className={styles.tabIndicator} />
                )}
              </button>
            ))}
          </div>
        </div>

        {/* Content Section */}
        <div className={styles.content}>
          <div className={styles.titleSection}>
            <p className={styles.description}>{activeItem?.content}</p>
          </div>
          <div className={styles.imageContainer}>
            {activeItem?.img === "" ? (
              <h1>Coming Soon...</h1>
            ) : (
              <img
                src={activeItem?.img}
                alt={activeItem?.title}
                width={350}
                height={600}
                className={styles.image}
              />
            )}
          </div>

          {/* Learn More Link */}
          <div className={styles.learnMore}>
            <a href={"https://fabits.com/product"}>
              <button className={styles.learnMoreButton}>Learn more</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordianMobile;

import { useState } from "react";
import styles from "./Testimonial.module.css";
import { testimonial } from "../../../assets/newHomepageImages.js";

//Images to upload
import Abhay from "../../../assets/NewHomePage/Testimonial/1.png";
import Vandana from "../../../assets/NewHomePage/Testimonial/2.png";
import Aishwarya from "../../../assets/NewHomePage/Testimonial/3.png";

const testimonials = [
  {
    quote:
      "With the Fabits plan, I finally have peace of mind about my finances",
    name: "Abhay - Systems Engineer",
    title: "TCS",
    image: Abhay,
  },
  {
    quote:
      "Thanks to the plan Fabits created for me, I’m confident I can send my daughter to university abroad in 12 years.",
    name: "Vandana - Admin",
    title: "Martec Europe",
    image: Vandana,
  },
  {
    quote:
      "The personal support I receive from Fabits is unmatched in the Indian financial industry today",
    name: "Aishwarya - Influencer",
    title: "@foodie_konkan",
    image: Aishwarya,
  },
];

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const isFirstSlide = currentIndex === 0;
  const isLastSlide = currentIndex === testimonials.length - 1;

  const handlePrevious = () => {
    if (!isFirstSlide) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNext = () => {
    if (!isLastSlide) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <div className={styles.carousel}>
      <div className={styles.slide}>
        <div className={styles.quoteSection}>
          <p className={styles.quote}>" {testimonials[currentIndex].quote} "</p>
        </div>
        <div className={styles.profileSection}>
          <img
            src={testimonials[currentIndex].image}
            alt={testimonials[currentIndex].name}
            className={styles.profileImage}
          />
          <div className={styles.profileDiv}>
            <h3 className={styles.name}>{testimonials[currentIndex].name}</h3>
            <p className={styles.title}>{testimonials[currentIndex].title}</p>
          </div>
        </div>
      </div>

      <button
        onClick={handlePrevious}
        className={`${styles.navigationButton} ${styles.prevButton} ${
          isFirstSlide ? styles.hidden : ""
        }`}
        aria-label="Previous testimonial"
        aria-hidden={isFirstSlide}
      >
        <span>{"<"}</span>
      </button>
      <button
        onClick={handleNext}
        className={`${styles.navigationButton} ${styles.nextButton} ${
          isLastSlide ? styles.hidden : ""
        }`}
        aria-label="Next testimonial"
        aria-hidden={isLastSlide}
      >
        <span>{">"}</span>
      </button>
    </div>
  );
};

export default Testimonial;

import React from "react";
import styles from "./Download.module.css";
import { downloadImages } from "../../../assets/newHomepageImages";

const Download = () => {
  return (
    <div className={styles.content}>
      <h1>
        <span>Download the</span> <span>Fabits App</span>
      </h1>
      <div>
        <button>
          <a
            href="https://apps.apple.com/in/app/fabits/id1600230367"
            alt="app store"
            target="_blank"
            rel="noreferrer"
          >
            App store <img src={downloadImages.apple} alt="apple" />
          </a>
        </button>
        <button>
          <a
            href="https://play.google.com/store/apps/details?id=com.fabits.mywealth&hl=en_IN&pli=1"
            alt="app store"
            target="_blank"
            rel="noreferrer"
          >
            Play store <img src={downloadImages.play} alt="play" />
          </a>
        </button>
      </div>
    </div>
  );
};

export default Download;

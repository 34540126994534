import React from "react";
import styles from "./Blogs.module.css";
import { blogsImages } from "../../../assets/newHomepageImages";


const blogs = [
  {
    id: 1,
    img: blogsImages.Education,
    text: "Add Wings To Your Child’s Future With A Child Education Fund.",
    link: "https://blog.fabits.com/2023/06/06/add-wings-to-your-childs-future-with-a-child-education-fund/",
  },
  {
    id: 2,
    img: blogsImages.Save,
    text: "How To Spend Less And Save More",
    link: "https://blog.fabits.com/2023/09/06/how-to-spend-less-and-save-more/",
  },
  {
    id: 3,
    img: blogsImages.Gold,
    text: "Why Gold Has A Strong Hold On The Indian Psyche",
    link: "https://blog.fabits.com/2023/06/08/why-gold-has-a-strong-hold-on-indian-psyche/",
  },
  {
    id: 4,
    img: blogsImages.synergy,
    text: "The Synergy Of Small Steps, Small Savings, And Small Investments",
    link: "https://blog.fabits.com/2023/05/22/the-synergy-of-small-steps-small-savings-and-small-investments/",
  },
];

const Blogs = () => {
  return (
    <div className={styles.content}>
      <div>
        <p>Our blogs</p>
        <p>
          <a href="https://blog.fabits.com/">Read all blogs</a>
        </p>
      </div>

      <div>
        {blogs.map((blog) => {
          const { id, img, link, text } = blog;
          return (
            <div key={id}>
              <div>
                <img src={img} alt={id} />
              </div>
              <div>
                <p>{text}</p>
                <p>
                  <a href={link}>Read full story</a>
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Blogs;

import React from "react";
import styles from "./Insurance.module.css";
import { pricingImages } from "../../../assets/newHomepageImages";


const Insurance = () => {
  return (
    <div className={styles.content}>
      <div className={styles.card}>
        <div className={styles.heading}>
          <div>
            <div>
              <span class="material-symbols-rounded">handshake</span>
            </div>
            <p>Health Insurance</p>
          </div>
          <p>
            Tax filling and insurance plans are customised based on your
            speciYour health deserves the right protection. Get a customised
            plan that matches your needs, family size, and lifestyle. We
            consider every factor to ensure you get comprehensive coverage at a
            cost that fits your budget.fic needs.
          </p>
        </div>
        <div className={styles.img}>
          <img src={pricingImages.health} alt="health insurance" />
        </div>

        <div className={styles.callback}>
          <button>Request a Callback</button>
        </div>
      </div>

      <div className={styles.card}>
        <div className={styles.heading}>
          <div>
            <div>
              <span class="material-symbols-rounded">handshake</span>
            </div>
            <p>Life Insurance</p>
          </div>
          <p>
            Secure your family’s future with a tailored life insurance plan.
            Pricing depends on your coverage needs, goals, and life stage, so
            you always get a solution that’s fair, affordable, and designed for
            you.
          </p>
        </div>

        <div className={styles.img}>
          <img src={pricingImages.life} alt="life insurance" />
        </div>

        <div className={styles.callback}>
          <button>Request a Callback</button>
        </div>
      </div>
    </div>
  );
};

export default Insurance;

import React from "react";
import styles from "./Investment.module.css";
import { pricingImages } from "../../../assets/newHomepageImages";

const Investment = () => {
  return (
    <div className={styles.content}>
      <div className={styles.heading}>
        <div>
          <div>
            <span class="material-symbols-rounded">handshake</span>
          </div>
          <p>Invest in Mutual Funds with Zero Service Fees</p>
        </div>
        <p>
          A small percentage is levied by the Asset Management Company (AMC),
          and we receive a portion of that- so you can invest without extra
          costs!
        </p>
      </div>

      <div className={styles.img}>
        <img src={pricingImages.plan} alt="plan" />
      </div>

      <div className={styles.fee}>
        <h1>₹ 0</h1>
        <p>Fabits charges no fees</p>
      </div>

      <div className={styles.callback}>
        <button>Request a Callback</button>
      </div>
    </div>
  );
};

export default Investment;

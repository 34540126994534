import React, { useState } from "react";
import styles from "./NewHomepage.module.css";
import HeroSection from "./HeroSection/HeroSection";
import Partnership from "./Partnership/Partnership";
import Blogs from "./Blogs/Blogs";
import Download from "./Download/Download";
import FAQ from "./FAQ/Faq";
import Grow from "./GrowYourMoney/Grow";
import Products from "./Products/Products";
import Testimonial from "./Testimonial/Testimonial";
import GetStarted from "./GetStarted/GetStarted";
import Accordian from "./Products/Accordian/Accordian";
import Investment from "./Pricing/Investment";
import Insurance from "./Pricing/Insurance";
import Tax from "./Pricing/Tax";
import useMediaQuery from "../../hooks/useMediaQuery";
import AccordianMobile from "./Products/Accordian_Mobile/AccordianMobile";
import { newHomePageImages } from "../../assets/newHomepageImages";

//Images to upload
import MF from '../../assets/NewHomePage/Products/MF.svg'
import Stocks from '../../assets/NewHomePage/Products/Stocks.svg'
import Health from '../../assets/NewHomePage/Products/Health.svg'
import Term from '../../assets/NewHomePage/Products/Term.svg'

const pricingData = [
  {
    label: "Investments",
    content: (
      <div>
        <div>
          <Investment />
        </div>
      </div>
    ),
  },
  {
    label: "Insurance",
    content: (
      <div>
        <div>
          <Insurance />
        </div>
      </div>
    ),
  },
  {
    label: "Tax",
    content: (
      <div>
        <div>
          <Tax />
        </div>
      </div>
    ),
  },
];

const NewHomepage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedImage, setSelectedImage] = useState("");
  const [openIndex, setOpenIndex] = useState(0);
  const isDesktop = useMediaQuery("(min-width: 1023px)");

  const accordionData = [
    [
      {
        title: "Goal Planning",
        content:
          "Build lasting wealth with personalised guidance and intelligent financial tools.",
        img: newHomePageImages.Goal_Planning,
      },
      {
        title: "Mutual Funds",
        content:
          "Grow your wealth with expertly curated mutual fund investments.",
        img: MF,
      },
      {
        title: "Stocks, ETFs, & IPOs",
        content:
          "Invest smartly and build your portfolio with ease, and low fees.",
        img: Stocks,
      },
      // {
      //   title: "Foreign Portfolio Review (coming soon)",
      //   content: "Optimize your international investments for better returns.",
      //   img: "",
      // },
    ],
    [
      {
        title: "Health Insurance",
        content:
          "Protect yourself and your family with the right health coverage.",
        img: Health,
      },
      {
        title: "Term Insurance",
        content: "Secure your loved ones' future with affordable life cover.",
        img: Term,
      },
    ],
    [
      {
        title: "Tax Filing",
        content: "",
        img: newHomePageImages.taxFiling,
      },
      {
        title: "Tax Planning",
        content: "",
        img: newHomePageImages.taxPlanning,
      },
      // {
      //   title: "RSUs, ESOP, ESPP",
      //   content: "",
      //   img: "",
      // },
      // {
      //   title: "Foreign Investment Planning",
      //   content: "",
      //   img: "",
      // },
    ],
  ];

  const productData = [
    {
      label: "Investments",
      content: (
        <div className={styles.tab1}>
          {!isDesktop ? (
            <>
              <div className={styles.page}>
                <AccordianMobile
                  accordionData={accordionData[activeTab]}
                  activeTabIndex={activeTab}
                />
              </div>
            </>
          ) : (
            <>
              <Accordian
                accordionData={accordionData}
                activeTab={activeTab}
                onImageChange={setSelectedImage}
                setOpenIndex={setOpenIndex}
                openIndex={openIndex}
              />
            </>
          )}
        </div>
      ),
    },
    {
      label: "Insurance",
      content: (
        <div className={styles.tab1}>
          {!isDesktop ? (
            <>
              <div className={styles.page}>
                <AccordianMobile
                  accordionData={accordionData[activeTab]}
                  activeTabIndex={activeTab}
                />
              </div>
            </>
          ) : (
            <>
              <Accordian
                accordionData={accordionData}
                activeTab={activeTab}
                onImageChange={setSelectedImage}
                setOpenIndex={setOpenIndex}
                openIndex={openIndex}
              />
              {/* <a className={styles.link} href={"https://fabits.com/product"}>
                Learn more
              </a> */}
            </>
          )}
        </div>
      ),
    },
    {
      label: "Tax Advisory",
      content: (
        <div className={styles.tab1}>
          {!isDesktop ? (
            <>
              <div className={styles.page}>
                <AccordianMobile
                  accordionData={accordionData[activeTab]}
                  activeTabIndex={activeTab}
                />
              </div>
            </>
          ) : (
            <>
              <Accordian
                accordionData={accordionData}
                activeTab={activeTab}
                onImageChange={setSelectedImage}
                setOpenIndex={setOpenIndex}
                openIndex={openIndex}
              />
              {/* <a className={styles.link} href={"https://fabits.com/product"}>
                Learn more
              </a> */}
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.herosection}>
        <HeroSection />
      </div>
      <div className={styles.grow}>
        <img src={newHomePageImages.doodle} alt="doodle" />
        <Grow />
      </div>
      <Testimonial />
      <div className={styles.grow}>
        <Products
          setOpenIndex={setOpenIndex}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabData={productData}
          title="Our Products"
          desc="Build lasting wealth with personalised guidance and intelligent
          financial tools."
        />
      </div>
      <Partnership />
      <div className={styles.blog}>
        <Blogs />
      </div>
      <div className={styles.pricing}>
        <Products
          setOpenIndex={setOpenIndex}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabData={pricingData}
          title="Pricing that works for you"
          desc="Transparent fees designed with your success in mind"
        />
      </div>
      <div className={styles.download}>
        <Download />
      </div>
      <FAQ />
      <div className={styles.getStarted}>
        <GetStarted />
      </div>
    </div>
  );
};

export default NewHomepage;

import React from "react";
import styles from "./tabs.module.css";

const Tabs = ({ tabs, activeTab, setActiveTab, setOpenIndex }) => {
  const handleTabClick = (index) => {
    setActiveTab(index);
    setOpenIndex(0);
  };

  return (
    <div className={styles["tabs-container"]}>
      <div className={styles.tabs}>
        {tabs.map((tab, index) => (
          <div
            key={index} // Always add a unique key when rendering lists
            className={`${styles.tab} ${
              index === activeTab ? styles.active : ""
            }`}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className={styles["tab-content"]}>
        {tabs[activeTab]?.content || "No content available"}
      </div>
    </div>
  );
};

export default Tabs;

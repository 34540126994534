export const newHomePageImages = {
  MF: "https://d3lgi69rk7563.cloudfront.net/newHomePage/Products/mf.svg",
  healthInsurance:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/Products/healthInsurance.svg",
  termInsurance:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/Products/termInsurance.svg",
  taxPlanning:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/Products/taxPlanning.svg",
  taxFiling:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/Products/taxFiling.svg",
  topFunds:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/Products/topFunds.svg",
  doodle: "https://d3lgi69rk7563.cloudfront.net/newHomePage/grow/doodle.svg",
  Goal_Planning:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/Products/Goal_Planning.svg",
};

export const heroSection = {
  Thermo:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/compLogos/Thermo.svg",
  Infosys:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/compLogos/Infosys.svg",
  GoDaddy:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/compLogos/GoDaddy.svg",
  Flipkart:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/compLogos/Flipkart.svg",
  Tavant:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/compLogos/Tavant.svg",
  Hsbc: "https://d3lgi69rk7563.cloudfront.net/newHomePage/compLogos/Hsbc.svg",
  Intel: "https://d3lgi69rk7563.cloudfront.net/newHomePage/compLogos/Intel.svg",
  Nvidia:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/compLogos/Nvidia.svg",
};

export const growSection = {
  check: "https://d3lgi69rk7563.cloudfront.net/newHomePage/grow/check.svg",
  consultation:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/grow/consultation.svg",
  goals: "https://d3lgi69rk7563.cloudfront.net/newHomePage/grow/goals.svg",
  vacation:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/grow/vacation.svg",
};

export const testimonial = {
  Rabia:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/testimonial/Image.jpeg",
  Dhrithi:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/testimonial/Image (1).jpeg",
  image:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/testimonial/Image (2).jpeg",
};

export const partnership = {
  partners: "https://d3lgi69rk7563.cloudfront.net/newHomePage/partners.png",
  partners_mob:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/partners_mob.png",
};

export const blogsImages = {
  Education:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/blogs/education.png",
  Save: "https://d3lgi69rk7563.cloudfront.net/newHomePage/blogs/save.png",
  Gold: "https://d3lgi69rk7563.cloudfront.net/newHomePage/blogs/gold.png",
  synergy: "https://d3lgi69rk7563.cloudfront.net/newHomePage/blogs/synergy.png",
};

export const downloadImages = {
  apple: "https://d3lgi69rk7563.cloudfront.net/newHomePage/apple.svg",
  play: "https://d3lgi69rk7563.cloudfront.net/newHomePage/play.svg",
};

export const getStartedImages = {
  pixel: "https://d3lgi69rk7563.cloudfront.net/newHomePage/Pixel.svg",
  doodle:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/getStarted/doodle.svg",
};

export const pricingImages = {
  plan: "https://d3lgi69rk7563.cloudfront.net/newHomePage/Pricing/Investment/planning.svg",
  health:
    "https://d3lgi69rk7563.cloudfront.net/newHomePage/Pricing/Insurance/health_insurance.png",
  life: "https://d3lgi69rk7563.cloudfront.net/newHomePage/Pricing/Insurance/life_insurance.png",
};

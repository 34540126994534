import React, { useEffect } from "react";
import styles from "./accordian.module.css";

const AccordionItem = ({ title, content, isOpen, onClick }) => {
  return (
    <div className={styles.accordionItem}>
      <button
        className={styles.accordionTrigger}
        onClick={onClick}
        style={{
          borderRadius: `${isOpen ? "8px 8px 0px 0px" : "8px"}`,
          backgroundColor: `${isOpen ? "white" : ""}`,
        }}
      >
        {title}
      </button>
      {content && isOpen && (
        <div
          style={{
            borderRadius: `${isOpen ? "0px 0px 8px 8px" : ""}`,
          }}
          className={styles.accordionContent}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default function Accordian({
  activeTab,
  onImageChange,
  accordionData,
  openIndex,
  setOpenIndex,
}) {
  useEffect(() => {
    if (accordionData[activeTab] && accordionData[activeTab][0]) {
      onImageChange(accordionData[activeTab][0].img);
    }
  }, [accordionData, activeTab, onImageChange]);

  const handleAccordionClick = (index, img) => {
    setOpenIndex(index === openIndex ? null : index);
    onImageChange(img);
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.tabDiv}>
          {accordionData[activeTab].map((item, index) => (
            <AccordionItem
              key={index}
              title={item.title}
              content={item.content}
              isOpen={openIndex === index}
              onClick={() => handleAccordionClick(index, item.img)}
            />
          ))}
        </div>

        <a className={styles.link} href={"https://fabits.com/product"}>
          Learn more
        </a>
      </div>
      <div>
        {accordionData[activeTab][openIndex]?.img === "" ? (
          <h1>Coming Soon...</h1>
        ) : (
          <>
            {accordionData && accordionData[activeTab] && openIndex !== null ? (
              <img
                src={accordionData[activeTab][openIndex]?.img}
                alt={accordionData[activeTab][openIndex]?.img || "Image"}
                width={400}
                height={300}
              />
            ) : accordionData && accordionData[activeTab] ? (
              <img
                src={accordionData[activeTab][0]?.img}
                alt={accordionData[activeTab][0]?.img || "Image"}
                width={400}
                height={300}
              />
            ) : (
              <p>No image available</p>
            )}
          </>
        )}
      </div>
    </div>
  );
}

import React from "react";
import styles from "./Tax.module.css";
import useMediaQuery from "../../../hooks/useMediaQuery";

const cards = [
  {
    id: 1,
    title: "Basic",
    desc: "For professionals with income lesser than 50 LPA",
    planfor: [
      "Salaried professionals",
      "Non-salaried professionals",
      "2 or lesser income streams ",
      "Rental or interest income ",
    ],
    discount: 40,
    currPrice: 1499,
    actualPrice: 2499,
    addCharge: 499,
    reccomended: false,
  },
  {
    id: 2,
    title: "Advanced",
    desc: "For capital gains/losses, plus all in Basic",
    planfor: [
      "Long term investors",
      "Traders",
      "More than 2 income streams",
      "Capital gains/losses from MFs, stocks, property, lottery, etc",
    ],
    discount: 40,
    currPrice: 5999,
    actualPrice: 9999,
    addCharge: 1999,
    reccomended: true,
  },
  {
    id: 3,
    title: "Pro",
    desc: "For salary >50 LPA, ESOPs/RSUs, plus all in Advanced",
    planfor: [
      "Income above 50 LPA",
      "ESOPs/RSU holders",
      "Directors, and CXOs",
      "PSU employees ",
    ],
    discount: 40,
    currPrice: 9999,
    actualPrice: 16500,
    addCharge: 2999,
    reccomended: false,
  },
];

const cardsMob = [
  {
    id: 1,
    title: "Advanced",
    desc: "For capital gains/losses, plus all in Basic",
    planfor: [
      "Long term investors",
      "Traders",
      "More than 2 income streams",
      "Capital gains/losses from MFs, stocks, property, lottery, etc",
    ],
    discount: 40,
    currPrice: 5999,
    actualPrice: 9999,
    addCharge: 1999,
    reccomended: true,
  },
  {
    id: 2,
    title: "Basic",
    desc: "For professionals with income lesser than 50 LPA",
    planfor: [
      "Salaried professionals",
      "Non-salaried professionals",
      "2 or lesser income streams ",
      "Rental or interest income ",
    ],
    discount: 40,
    currPrice: 1499,
    actualPrice: 2499,
    addCharge: 499,
    reccomended: false,
  },
  {
    id: 3,
    title: "Pro",
    desc: "For salary >50 LPA, ESOPs/RSUs, plus all in Advanced",
    planfor: [
      "Income above 50 LPA",
      "ESOPs/RSU holders",
      "Directors, and CXOs",
      "PSU employees ",
    ],
    discount: 40,
    currPrice: 9999,
    actualPrice: 16500,
    addCharge: 2999,
    reccomended: false,
  },
];

const Tax = () => {
  const isDesktop = useMediaQuery("(min-width: 1023px)");

  return (
    <div className={styles.content}>
      {(isDesktop ? cards : cardsMob).map((card) => {
        const {
          id,
          title,
          desc,
          planfor,
          currPrice,
          actualPrice,
          addCharge,
          reccomended,
          discount
        } = card;
        return (
          <div
            key={id}
            style={{
              border: `${
                reccomended ? "1px solid #242F4E" : "1px solid #AAAAAA"
              }`,
            }}
            className={styles.card}
          >
            <div className={styles.heading}>
              <div>
                <div>
                  <span class="material-symbols-rounded">handshake</span>
                </div>
                <p>{title}</p>
              </div>
              <p>{desc}</p>
            </div>

            <div className={styles.planFor}>
              <p>This plan is perfect for</p>
              <div>
                {planfor.map((plan, index) => (
                  <div key={index}>
                    <div>
                      <span class="material-symbols-rounded">check</span>
                    </div>
                    <div>{plan}</div>
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.pricing}>
              <div>
                <p>
                  ₹{" "}
                  {currPrice.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                    currency: "INR",
                  })}
                </p>
                <p>
                  ₹{" "}
                  {actualPrice.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                    currency: "INR",
                  })}
                </p>
                <p>(-{discount}%)</p>
              </div>
              <p>One time service fee, inclusive of GST</p>
            </div>

            <p className={styles.addCharge}>
              Additional Charge <span>₹{addCharge}/filing</span>
            </p>

            <div className={styles.btn}>
              <button>Select Plan</button>
            </div>

            {reccomended && (
              <div className={styles.reccomended}>
                <span>Most popular</span>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Tax;

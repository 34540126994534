import React from "react";
import styles from "./Grow.module.css";
import { growSection } from "../../../assets/newHomepageImages";


const ticks = [
  { id: 1, data: "PEOPLE FIRST" },
  { id: 2, data: "TECH ENABLED" },
  { id: 3, data: "NO JARGON" },
  { id: 4, data: "PROFESSIONAL GUIDANCE" },
];

const Grow = () => {
  return (
    <div className={styles.content}>
      <div className={styles.heading}>
        <h1>How do we grow your money</h1>
        <p>
          Build lasting wealth with personalised guidance and intelligent
          financial tools.
        </p>
        <div>
          {ticks.map((tick) => {
            return (
              <div key={tick.id}>
                <img src={growSection.check} alt="tick" />
                <p>{tick.data}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.card}>
        <div className={styles.card1}>
          <div>
            <div>
              <div>01</div>
            </div>
            <h1>Get a Free Consultation</h1>
            <p>Talk Money, No Pressure — First Consultation Free</p>
          </div>
          <div className={styles.img1}>
            <img src={growSection.consultation} alt="consultation" />
          </div>
        </div>
        <div className={styles.card1}>
          <div>
            <div>
              <div>02</div>
            </div>
            <h1>Set Your Financial Goals</h1>
            <p>Where Do You Want Your Money to Take You?</p>
          </div>
          <div className={styles.img2}>
            <img src={growSection.goals} alt="goals" />
          </div>
        </div>
        <div className={styles.card1}>
          <div>
            <div>
              <div>03</div>
            </div>
            <h1>Start Building Habits with Fabits</h1>
            <p>Use the app to Elevate Your Finances, Habit by Habit</p>
          </div>
          <div className={styles.img3}>
            <img src={growSection.vacation} alt="vacation" />
          </div>
        </div>
      </div>

      <div className={styles.btn}>
        <a href="https://mywealth.fabits.com" target="_blank" rel="noreferrer">
          <button>Start Now</button>
        </a>
      </div>
    </div>
  );
};

export default Grow;

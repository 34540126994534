import React from "react";
import styles from "./Faq.module.css";
import Accordian from "./Accordian/Accordian";

const faq = [
  {
    id: 1,
    question: "Are my investments with Fabits protected?",
    answer: (
      <p>
        Yes, your investments with Fabits are protected. We partner with
        trusted, SEBI-registered entities and custodians to ensure your
        investments are securely held. Additionally, Fabits operates under
        strict regulatory guidelines to safeguard your funds and data.
      </p>
    ),
  },
  {
    id: 2,
    question:
      "Does Fabits charge me a fee for taking money and investments out of my account?",
    answer: (
      <p>
        No, Fabits does not charge any withdrawal fees. You can withdraw your
        money or investments anytime without any extra charges. However,
        depending on the asset type, there may be regulatory or third-party
        charges, such as exit loads or capital gains taxes, which we will
        transparently display.
      </p>
    ),
  },
  {
    id: 3,
    question: "How does Fabits work?",
    answer: (
      <>
        <p>
          Fabits is your personal finance assistant that helps you save and
          invest smarter.
        </p>
        <p>Here’s how it works:</p>
        <ol className={styles.list}>
          <li>
            <span>Set Goals: </span>
            You start by defining your financial goals—whether it’s buying a
            house, planning for a vacation, or building wealth.
          </li>
          <li>
            <span>Personalized Plans: </span>
            Fabits creates tailored investment plans based on your goals, risk
            tolerance, and timeline.
          </li>
          <li>
            <span>Automation: </span>
            We help you automate your savings and investments, making it easy to
            stay on track.
          </li>
          <li>
            <span>Tracking: </span>You can monitor your progress in real-time
            and get insights to optimize your financial journey.
          </li>
        </ol>
      </>
    ),
  },
  {
    id: 4,
    question: "What assets does Fabits invest in?",
    answer: (
      <>
        <p>Fabits invests in a diversified range of assets, including:</p>
        <ul className={styles.list}>
          <li>
            <span>Mutual Funds: </span>Equity, debt, and hybrid funds tailored
            to your goals.
          </li>
          <li>
            <span>Stocks: </span>For long-term wealth building.
          </li>
          <li>
            <span>Fixed-Income Assets: </span>Such as bonds and fixed deposits
            for stable returns.
          </li>
          <li>
            <span>ETFs: </span>For low-cost, passive investment strategies.
          </li>
        </ul>
        <p>
          Our recommendations are customized to your needs, focusing on
          achieving sustainable growth.
        </p>
      </>
    ),
  },
];

const FAQ = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>Frequently Asked Questions</h1>

        <div className={styles.faqContainer}>
          {faq.map((item, index) => (
            <div key={index}>
              <Accordian
                key={item.id}
                title={item.question}
                desc={item.answer}
              />
              <hr className={styles.ruler} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;

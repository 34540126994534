import React from "react";
import styles from "./Partnership.module.css";
import { partnership } from "../../../assets/newHomepageImages";

const Partnership = () => {
  return (
    <div className={styles.content}>
      <h1>We are partnered with</h1>
      <div>
        <img
          className={styles.img1}
          src={partnership.partners}
          alt="partners"
        />
        <img
          className={styles.img2}
          src={partnership.partners_mob}
          alt="partners"
        />
      </div>
    </div>
  );
};

export default Partnership;

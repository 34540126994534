import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Mainpage from "./components/Mainpage";
import Product from "./components/Product";
import ContactUs from "./components/ContactUs";
import Partnerships from "./components/Partnerships";
import Pricing from "./components/Pricing";
import About from "./components/About";
import Footer from "./components/Footer";
import TermsandConditions from "./Text-Files/TermsandConditions";
import PrivacyPolicy from "./Text-Files/PrivacyPolicy";
import Disclosure from "./Text-Files/Disclosure";
import PoliciesAndProcedures from "./Text-Files/PoliciesAndProcedures";
import InvestorCharter from "./Text-Files/InvestorCharter";
import InvestorComplaints from "./Text-Files/InvestorComplaints";
import ReactGA from "react-ga4";
import AccountClosure from "./Text-Files/AccountClosure";
import Insurance from "./components/Insurance";
import KRA from "./components/KRA";
import TaxFiling from "./components/TaxFiling";
import InvestorAwareness from "./Text-Files/InvestorAwareness";
import clevertap from "clevertap-web-sdk";
import Calculator from "./components/Calculator/Calculator";
import Sip from "./components/Calculator/SIP_Calculator/Sip";
import Nps from "./components/Calculator/NPS_Calculator/Nps";
import Itc from "./components/Calculator/IT_Calculator/Itc";
import Ppf from "./components/Calculator/PPF_Calculator/Ppf";
import FD from "./components/Calculator/FD_Calculator/FD";
import CompoundInterest from "./components/Calculator/Compound_Interest_Calculator/CompoundInterest";
// import Gratuity from "./components/Calculator/Gratuity_Calculator/Gratuity";
import SimpleInterest from "./components/Calculator/Simple_Interest_Calculator/SimpleInterest";
import Recurring from "./components/Calculator/RD_Calculator/Recurring";
// import SSY from "./components/Calculator/SSY_Calculator/SSY";
import MutualFund from "./components/Calculator/MutualFund_Calculator/MutualFund";
import Emi from "./components/Calculator/EMI_Calculator/Emi";
import NotFound from "./components/NotFound";
import NpsLp from "./components/Marketing_Landing_Pages/NPS/Nps";
import Gold from "./components/ETF/Gold";
import Finance from "./components/Diwali_Landing_Page/Diwali/Finance";
import NewHomepage from "./components/NewHomepage/NewHomepage";

function App() {
  let CLEVER_TAP_KEY = process.env.REACT_APP_CLEVER_TAP_KEY;

  useEffect(() => {
    ReactGA.initialize("G-QP5NR9NGYY");
    clevertap.privacy.push({ optOut: false });
    clevertap.privacy.push({ useIP: false });
    clevertap.init(CLEVER_TAP_KEY);
    clevertap.spa = true;
  }, []);

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<NewHomepage />} />
        <Route path="/oldPage" element={<Mainpage />} />
        <Route path="/product" element={<Product />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Partnerships" element={<Partnerships />} />
        <Route path="/Pricing" element={<Pricing />} />
        <Route path="/About" element={<About />} />
        <Route path="/TermsandConditions" element={<TermsandConditions />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Disclosure" element={<Disclosure />} />
        <Route path="/Insurance" element={<Insurance />} />

        <Route path="/goldetf" element={<Gold />} />

        {/* Marketing LP Routes */}
        <Route path="/nps" element={<NpsLp />} />

        <Route path="/FinancialReview" element={<Finance />} />

        {/* Calculator Routes */}
        <Route path="/calculator" element={<Calculator />} />
        <Route path="/calculator/sip" element={<Sip />} />
        <Route path="/calculator/nps" element={<Nps />} />
        {/* <Route path="/calculator/itc" element={<Itc />} /> */}
        <Route path="/calculator/ppf" element={<Ppf />} />
        {/* <Route path="/calculator/ssy" element={<SSY />} /> */}
        <Route path="/calculator/fd" element={<FD />} />
        <Route
          path="/calculator/compound-interest-calculator"
          element={<CompoundInterest />}
        />
        {/* <Route path="/calculator/gratuity-calculator" element={<Gratuity />} /> */}
        <Route
          path="/calculator/simple-interest-calculator"
          element={<SimpleInterest />}
        />
        <Route path="/calculator/rd-calculator" element={<Recurring />} />
        <Route
          path="/calculator/mutual-fund-returns-calculator"
          element={<MutualFund />}
        />
        <Route path="/calculator/emi-calculator" element={<Emi />} />

        <Route
          path="/PoliciesAndProcedures"
          element={<PoliciesAndProcedures />}
        />
        <Route path="/InvestorCharter" element={<InvestorCharter />} />
        <Route path="/InvestorAwareness" element={<InvestorAwareness />} />
        <Route path="/AccountClosure" element={<AccountClosure />} />
        <Route
          path="/InvestorComplaints"
          element={<InvestorComplaints data title />}
        />
        <Route path="/KRA" element={<KRA />} />
        <Route path="/TaxFiling" element={<TaxFiling />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
